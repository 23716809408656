@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}


.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: all 1s ease;

}


.reveal.active{
  transform: translateY(0px);
  opacity: 1;
}

@layer base {
  button{
    @apply border border-black rounded-xl px-5 py-1
  }
}
