a,
img,
span,
textarea,
button,
input,
address,
select{
    display: block;
}

li{
    list-style: none;
}

a{
    color: inherit;
    text-decoration: none;
}

img{
    height: auto;
}

input,
button,
select,
textarea{
  background: none;
  border: none;
  font: inherit;
}

input,
select,
textarea{
    width: 100%;
    outline: none;
}

button {
    cursor: pointer;
}


/*REUSED STYLES*/
.container{
    padding-inline: 16px;
}

.separate{
    width: 8px;
    height: 8px;
    border: 1px solid goldenrod;
    transform: rotate(45deg);
}

.contact-label{
    font-weight: 400;
}

.contact-number{
    color: goldenrod;
    max-width: max-content;
    margin-inline: auto;
}

.contact-number{
    position: relative;
    max-width: max-content;
}

.hover-underline::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    border-bottom: 1px solid goldenrod;   
    transform: scaleX(0.2);
    opacity: 0;
    transition: all .5s ease-in;  
}

.hover-underline:is(:hover, :focus-visible)::after{
    transform: scaleX(1);
    opacity: 1;
}

.contact-number::after{
    bottom: -5px;
}

.text-center{
    text-align: center;
}

.img-cover{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section-subtitle{
    position: relative;
    color: orangered;
    font-weight: 400;
    text-transform: uppercase;
    margin-block-end: 12px;
}

.btn{
    position: relative;
    color: goldenrod;
    text-transform: uppercase;
    max-width: max-content;
    border: 2px solid goldenrod;
    padding: 12px 45px;
    overflow: hidden;
    z-index: 1;
}

.btn::before{
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 200%;
    height: 200%;
    border-radius: 100px;
    background-color: goldenrod;
    transition: all .5s ease;
    z-index: -1;
}

.btn .text{
    transition: all .5s ease;
}

.btn .text-2{
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    min-width: max-content;
    color: rgba(0, 0, 0, 0.786);
}

.btn:is(:hover, :focus-visible)::before{
    bottom: -50%;
}

.btn:is(:hover, :focus-visible) .text-1{ transform: translateY(-40px);}

.btn:is(:hover, :focus-visible) .text-2{
     top: 50%; 
     transform: translateY(-50%, -50%);
}

.btn-secondary{
    background-color: goldenrod;
    color: black;
}

.btn-secondary::before{
   background-color: rgb(18, 18, 18);
   
}

.btn-secondary .text-2{
    color: #fff;
}

.has-before,
.has-after{
    position: relative;
    z-index: 1;
}

.has-before::before,
.has-after::after{
  content: "";
  position: absolute;
}

.section{
    position: relative;
    padding-block: 10px;
    overflow: hidden;
    z-index: 1;
}

.bg-black-10{
   background-color: rgba(0, 0, 0, 0.753);  
}

.grid-list{
    display: grid;
    gap: 40px;
}

.hover\:shine{
    position: relative;
}

.hover\:shine::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-image: linear-gradient(to right, transparent 0%, #fff6 100%);
    transform: skewX(-0.08turn) translateX(-180%);
}

.hover\:shine:is(:hover, :focus-within)::after{
    transform: skewX(-0.08turn) translateX(275%);
    transition: all .5s ease;
}

.btn-text{
    color: goldenrod;
    padding-block-end: 4px;
    margin-inline: auto;
    text-transform: uppercase;
    transition: all .5s ease;
    font-weight: bold;
    letter-spacing: 3px;
}

.btn-text:is(:hover, :focus-visible){color: #fff;}

.w-100{width: 100%;}

.input-field{
    background-color: rgb(30, 30, 30);
    color: #fff;
    height: 56px;
    padding: 10px 20px;
    border: 1px solid whitesmoke;
    margin-block-end: 20px;
    outline: none;
    transition: border-color all .5s ease;
}

.input-field::placeholder{color: inherit;}

.input-field:focus{border-color: goldenrod;}

.icon-wrappper{
    position: relative;
    margin-block-end: 20px;
}

.icon-wrappper .input-field{
    margin-block-end: 0;
    padding-inline-start: 40px;
    appearance: none;
    cursor: pointer;
}


.about{
 background-color: #fff;
 color: #000;  
 width: 90%;
 margin: 0 auto;
}

.about .section-text{
    margin-block: 15px 30px;
    font-weight: 100;
    font-style: italic;
}

.about .btn{
    margin-inline: auto;
    margin-block-start: 26px;
}

.about button{
    margin: 0 auto;
}

.about .container{
    display: grid;
    gap: 90px;
    align-items: center;
    grid-template-columns: 50% 40%;
}

.about-banner{
    position: relative;
    margin-block-end: 120px;
}

.about-banner > .w-100{
    padding-inline-start: 50px;
}

.about .abs-img{
    position: absolute;
}

.about .abs-img::before{
    z-index: -1;
}

.about .abs-img-1{
    bottom: -120px;
    left: 0;
    width: 220px;
    padding-block: 50px;
}

.about .abs-img-2{
    top: -65px;
    right: 0;
    overflow: hidden;
}

.menu .section-title{
    margin-block-end: 40px;
}

.menu .grid-list{
    margin-block-end: 50px;
}

.title-wrapper{
    display: flex;
    align-items: center;
    gap: 20px;
}

.menu-card{
    display: flex;
    align-items: flex-start;
    gap: 20px;
}
.hover\:card .card-banner{
    background-color: #00aa00;
}

.hover\:card .card-banner .img-cover{
    transition: all .5s ease;
    width: 150px;
    border-radius: 24px;
}

.hover\:card:is(:hover, :focus-within) .card-banner .img-cover{
    opacity: 0.7;
    transform: scale(1.05);
}

.menu-card .card-banner{
    flex-shrink: 0;
    border-radius: 24px;
}

.menu-card .card-title{
    transition: all .5s ease;
}

.menu-card .card-title:is(:hover, :focus-visible){color: #00aa00;}

.menu-card .badge{
    background-color: #00aa00;
    color: rgb(20, 20, 20);
    max-width: max-content;
    text-transform: uppercase;
    padding-inline: 10px;
    margin-block-start: 10px;
}

.menu-card .span{
    color: #00aa00;
    font-weight: 600;
    margin-block: 10px;
}

.menu-card .card-text{
    color: rgb(0, 0, 0);
    font-style: italic;
    align-items: flex-start;

}

.menu .btn{
    margin-inline: auto;
    margin-block-start: 26px;
}

@media screen and (max-width: 1000px){
   .about .container{
      grid-template-columns: 1fr;
   }
}

@media screen and (max-width: 600px){
    .about .container{
       grid-template-columns: 1fr;
    }
 }