.about__container{
   display: flex;
   justify-content: center;
   text-align: center;
   margin: 0 auto;
}

.about__right{
    width: 70%;
    text-align: center;
}


.about__cards{
    margin: 3rem 0 1.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
}

.card.about__card{
    text-align: center;
    background: #d52341e7;
    border-radius: 10px;
    color: #ffff;
    box-shadow: .5rem .5rem .7rem rgba(169, 169, 169, 0.465);
    transition: all 500ms ease; 
}

.about__card-icon{
    font-size: 1.8rem;
    color: #ffff;
    border-radius: 10px;
    width: fit-content;
    margin-inline: auto;
    padding: 0.6rem;
    display: flex;
    align-items: center;
    transition: all 500ms ease;
}

.cards.about__card:hover{
    transform: translateY(-0.5rem);
    box-shadow: 3px 5px 10px darkgrey;
}

.card.about__card h5{
    margin: 1rem 0;
}

.about__right p{
    margin-bottom: 0.8rem;
}

.about__right .btn{
    margin-top: 2.5rem;
}


@media  screen and (max-width: 600px){

    .about__right{
        width: 90%;
        margin: 0 auto 3rem;
    }

    .about__cards{
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

} 